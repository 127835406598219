<template>
  <div class="app-container"
       v-loading.fullscreen="isLoading"
       element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-row class="row-card">
      <el-col :lg="{span: 18, offset: 3}">
        <el-card>
          <h2 class="heading page-heading">
            {{ $t(`page_purpose_payment.title.${unit.unitType}`) }}
          </h2>
          <div class="unit-info">
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="home"/>
              <span>
                {{ $t('page_purpose_payment.card_info.unit_title') }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ unit.unitTitle }}
              </span>
            </div>
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="flag"/>
              <span>
                {{ $t('page_purpose_payment.card_info.unit_purpose_title') }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ unit.purposeTitle }}
              </span>
            </div>
            <UnitProgressRenderless
                :progress="unit.collectedMoney"
                :total="unit.moneyPurpose"
            >
              <template #default="{progressDifference}">
                <div class="d-flex align-center unit-info__item">
                  <v-icon color="#606266" name="attach_money"/>
                  <span>
                    {{ $t('page_purpose_payment.card_info.unit_money_difference') }}
                  </span>
                  <span>
                    &nbsp;-&nbsp;
                  </span>
                  <span class="font-semi-bold">
                    {{ $options.moneyFormat(progressDifference) }}
                  </span>
                </div>
              </template>
            </UnitProgressRenderless>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row class="row-card">
      <el-col :lg="{span: 18, offset: 3}">
        <el-card>
          <el-form
              label-position="top"
              :rules="formRules"
              size="medium"
              status-icon
              :model="form"
              ref="form"
          >
            <el-row :gutter="24" :class="{'row--no-wrap': !isMd}">
              <el-col>
                <el-form-item
                    prop="amount"
                    :label="$t('page_purpose_payment.fields.amount')"
                >
                  <el-input v-model="form.amount">
                    <template #suffix>
                      PLN
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="!isLoggedIn">
                <el-form-item
                    prop="email"
                    :label="$t('page_purpose_payment.fields.email')"
                >
                  <el-input v-model.trim="form.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="isVolunteerCode">
              <el-col>
                <volunteer-code-input form-item-prop="volunteerCode"
                                      v-model="form.volunteerCode"
                />
              </el-col>
            </el-row>
            <el-row v-if="!isLoggedIn">
              <el-col>
                <div class="isRegulationsAccept">
                <el-form-item>

                  <el-checkbox v-model="isRegulationsAccept">
                    <div class="d-flex flex-wrap agreement-text">
                      <span class="field-star text--danger">*</span>
                        {{ $t('page_purpose_payment.agreement.text') }}
                      <el-link
                          :href="`${$options.TERM_OF_REGULATION_DOCUMENT}`"
                          :underline="false"
                          type="primary"
                          class="innertext"
                      >
                        {{ $t('page_purpose_payment.agreement.link_text') }}
                      </el-link>
                    </div>
                  </el-checkbox>

                </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-checkbox v-model="isStandingOrder">
                    <div class="d-flex flex-wrap agreement-text">
                        {{ $t('page_purpose_payment.standing_order.text') }}
                    </div>
                  </el-checkbox>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <div class="payment-card-actions">
                  <div>
                    <el-button :disabled="!isLoggedIn && !isRegulationsAccept" type="primary" @click="goToPayment">
                      {{ $t('page_purpose_payment.btn_submit') }}
                    </el-button>
                    <el-button @click="goToUnitPage">
                      {{ $t('page_purpose_payment.btn_cancel') }}
                    </el-button>
                  </div>
                  <div>
                    <v-button-toggle ref="volunteerBtnRef" v-model="isVolunteerCode">
                      {{ $t('page_adoption_payment.btn_volunteer') }}
                    </v-button-toggle>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <PaymentProviderCard v-if="!isStandingOrder" />
    <BankPaymentCard v-if="isStandingOrder" />
  </div>
</template>

<script>
import {numberValueValidator} from '../../shared/validators/numberValueValidator'
import {aboveZeroNumberValidator} from '../../shared/validators/aboveZeroNumberValidator'
import {requestPurposePayment} from '../shared/services/payment-service'
import {fetchUnitPaymentInfo} from '../../client/unit-page/services/unit-sevice'
import {moneyFormat} from '../../shared/utils/moneyFormat'
import VIcon from '../../components/ui/v-icon/VIcon'
import UnitProgressRenderless from '../../client/components/units/unit-progress/UnitProgressRenderless'
import {mapGetters, mapState, mapMutations, mapActions} from 'vuex'
import PaymentProviderCard from '../shared/components/PaymentProviderCard'
import BankPaymentCard from '../shared/components/BankPaymentCard'
import {UNIT_PURPOSES_ROUTE_NAME, STANDING_ORDER_PAYMENT_ROUTE_NAME} from '../../shared/constants/app-routes-names'
import {TERM_OF_REGULATION_DOCUMENT} from '../../shared/constants/app-documents'
import VButtonToggle from '../../components/ui/v-button-toggle/VButtonToggle'
import VolunteerCodeInput from '../shared/components/volunteer-code-input/VolunteerCodeInput'

const MAX_MONEY_AMOUNT = 100_000

export default {
  name: 'PurposePayment',
  components: {
    VolunteerCodeInput,
    VButtonToggle,
    PaymentProviderCard,
    BankPaymentCard,
    UnitProgressRenderless,
    VIcon
  },
  TERM_OF_REGULATION_DOCUMENT,
  moneyFormat,
  data() {
    return {
      isLoading: true,
      isVolunteerCode: false,
      unit: {
        unitTitle: '',
        unitType: '',
        collectedMoney: 0,
        moneyPurpose: 0,
      },
      form: {
        email: '',
        amount: 10,
        volunteerCode: ''
      },
      formRules: {
        email: [
          {required: true, message: this.$t('validators.field_required')},
          {type: 'email', message: this.$t('validators.incorrect_email')}
        ],
        amount: [
          {required: true, message: this.$t('validators.field_required')},
          {validator: numberValueValidator, trigger: 'change'},
          {validator: aboveZeroNumberValidator, trigger: 'change'},
          {validator: this.maxRangeValidator, trigger: 'change'},
        ]
      },
      isRegulationsAccept: false,
      isStandingOrder: false
    }
  },
  created() {
    this.fetchUnitPreview()
    this.form.volunteerCode = this.refererCode
    if(this.isLoggedIn) {
      this.form.email = this.getUserEmail
    }

    if (this.form.volunteerCode) {
      this.isVolunteerCode = true
    }
  },
  inject: ['responsive'],
  computed: {
    ...mapGetters({
      isLoggedIn: 'authState/isLoggedIn',
      getUserEmail: 'authState/getUserEmail',

    }),
    ...mapState(
      'unitState', 
      {
      refererCode: state => state.refererCode
      },
    ),
    isMd() {
      return this.responsive.isMd
    }
  },
  methods: {
    maxRangeValidator(_, value, callback) {
      if (value > MAX_MONEY_AMOUNT) {
        callback(new Error(`${this.$t('page_purpose_payment.validators.max_money_value', {maxValue: MAX_MONEY_AMOUNT})}`))
      }
      callback()
    },
    async fetchUnitPreview() {
      this.isLoading = true
      const {unitID} = this.$route.params

      const {
        unitType,
        unitTitle,
        moneyPurpose,
        collectedMoney,
        purposeTitle
      } = await fetchUnitPaymentInfo(unitID)
      this.unit = {
        unitType,
        unitTitle,
        moneyPurpose,
        collectedMoney,
        purposeTitle
      }
      this.isLoading = false
    },
    goToPayment() {

      this.$refs.form.validate((valid) => {
        if (!valid) return

        if(!this.isStandingOrder) {
          this.isLoading = true
          const {unitID} = this.$route.params
          const { email, amount, volunteerCode } = this.form
          requestPurposePayment({
            amount: Number(amount),
            email,
            unitID,
            volunteerCode: this.isVolunteerCode ? volunteerCode : null
          })
          .then((redirectUrl) => {
            window.location.href = redirectUrl
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: this.$t(`alerts.payments.${e.data.message}`)
            })
          })
          .finally(() => {
            this.isLoading = false
          })
        } else {

          const { amount, email, volunteerCode } = this.form
          const {unitID} = this.$route.params
          this.$router.push({
          name: STANDING_ORDER_PAYMENT_ROUTE_NAME,
          params: {
            unitID,
            amount,
            email,
            volunteerCode
          }
      })

        }
      })
    },
    goToUnitPage() {
      const {unitID} = this.$route.params
      this.$router.push({
        name: UNIT_PURPOSES_ROUTE_NAME,
        params: {
          unitID
        }
      })
    },
    setVolunteerId() {
      if(this.form.volunteerCode) {
        this.setVolunteerCode(this.form.volunteerCode)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.spacer {
  margin-left: auto;
}
</style>


<template>
  <el-row>
    <el-col :lg="{span: 18, offset: 3}">
      <el-card>
        <div class="flex-cont"> 
            <img class="bank-payment-img" :src="$options.paymentBankImage" alt="bank"> Przelew tradycyjny
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import paymentBankImage from '../../../../assets/images/ic_bank_transfer.svg'

export default {
  name: 'BankPaymentCard',
  paymentBankImage,
}
</script>

<style scoped>
  .flex-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 19px;
    gap:10px;
    color: #909399;
  }

</style>
